import {call, put, select, takeLatest} from 'redux-saga/effects'
import {Api} from "laravel-request";
import * as scenarioActions from "../actions/scenario";
import * as authActions from "../actions/auth";
import {handleScenario} from "../sagas/auth";
import {ClientConstants, ClientModel} from "finhelper"


function* callHelperGotoFunc(action)
{
  const {step} = action.payload;
  const state = yield select();

  if(typeof state.scenario.helperGotoStep === 'function')
  {
    state.scenario.helperGotoStep(step);
  }
}

function* callHelperCloseFunc(action)
{
  const state = yield select();

  if(typeof state.scenario.helperClose === 'function')
  {
    state.scenario.helperClose();
  }
}

function* startScenario(action)
{
  try {
    const {callback, scenario} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('user-scenario', 'start', {
        scenario: scenario
      }).call((response, status, xhr) => {
        resolve(response);
      }, (errors) => {
        console.error(errors);
      });
    });

    let response = yield promise;
    yield put(authActions.handleGetUser(response));

    yield handleScenario(response);

    yield put(authActions.handleAuth());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* backScenario(action)
{
  try {
    const {callback} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('user-scenario', 'back', {}).call((response, status, xhr) => {
        callback();
        resolve(response);
      }, (errors) => {
        console.error(errors);
      });
    });

    let response = yield promise;
    yield put(authActions.handleGetUser(response));

    yield handleScenario(response);

    yield put(authActions.handleAuth());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* moveScenario(action)
{
  try {
    const {callback} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('user-scenario', 'move', {}).call((response, status, xhr) => {
        callback();
        resolve(response);
      }, (errors) => {
        console.error(errors);
      });
    });

    let response = yield promise;
    yield put(authActions.handleGetUser(response));

    yield handleScenario(response);

    yield put(authActions.handleAuth());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* setStepScenario(action)
{
  try {
    const {callback, step, scenario} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('user-scenario', 'set', {
        step: step,
        scenario: scenario,
      }).call((response, status, xhr) => {
        callback();
        resolve(response);
      }, (errors) => {
        console.error(errors);
      });
    });

    let response = yield promise;
    yield put(authActions.handleGetUser(response));

    yield handleScenario(response);

    yield put(authActions.handleAuth());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* finishScenario(action)
{
  try {
    const {callback, scenario} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('user-scenario', 'finish-any', {
        scenario: scenario
      }).call((response, status, xhr) => {
        document.body.style.overflow = 'visible'

        callback();
        resolve(response);
      }, (errors) => {
        console.error(errors);
      });
    });

    let response = yield promise;
    yield put(authActions.handleGetUser(response));

    yield handleScenario(response);

    yield put(authActions.handleAuth());
    yield put(scenarioActions.handleEndTutorial());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* getFormAvatar(action)
{

  try {
    const state = yield select();

    const promise = new Promise((resolve, reject) => {
        Api.get('profile', 'avatar', {
          id: state.scenario.data.id
        }).call((response) => {
          resolve(response);
        }, (errors) => {
          console.error(errors);
        });
    });

    let response = yield promise;
    yield put(scenarioActions.handleFormAvatar(response.data));
  } catch (err) {
    console.error(err);
  }
}

function* getFormData(action)
{
  try {
    const state = yield select();
    if(state.auth.data)
    {
      switch (state.auth.data.role)
      {
        case ClientConstants.CLIENT:

          yield put(scenarioActions.handleFormData(state.auth.data));
          yield put(scenarioActions.handleFormAvatar(state.auth.data));

          if(action.payload && typeof action.payload.callback === "function")
          {
            yield call(action.payload.callback);
          }
          break;
        case ClientConstants.MANAGER:
        case ClientConstants.OWNER:
        case ClientConstants.DEPARTMENT_BOSS:
        case ClientConstants.DIRECTOR:
          const promiseManager = new Promise((resolve, reject) => {
            ClientModel.fetch().orderBy('id', 'ASC').all((response) => {
              if(response.data.length > 0)
              {
                let client = localStorage.getItem('client') ? JSON.parse(localStorage.getItem('client')) : null;
                if(client)
                {
                  let id = client.id;

                  if(id)
                  {
                    let client = null;
                    response.data.map((item) =>
                    {
                      if(item.id === id)
                      {
                        client = item;
                      }
                    });

                    resolve(client);
                  }
                }else{
                  resolve(response.data[0]);
                }
              }
            }, (response) => {
              //TODO
            })
          });

          let dataManager = yield promiseManager;

          if(dataManager)
          {
            yield put(scenarioActions.handleFormData(dataManager));
            yield put(scenarioActions.handleFormAvatar(dataManager));
          }

          if(action.payload && typeof action.payload.callback === "function")
          {
            yield call(action.payload.callback);
          }
          break;
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export default function* watcher() {
  yield takeLatest(scenarioActions.startScenario, startScenario)
  yield takeLatest(scenarioActions.setStepScenario, setStepScenario)
  yield takeLatest(scenarioActions.backScenario, backScenario)
  yield takeLatest(scenarioActions.moveScenario, moveScenario)
  yield takeLatest(scenarioActions.finishScenario, finishScenario)
  yield takeLatest(scenarioActions.getFormData, getFormData)
  yield takeLatest(scenarioActions.getFormAvatar, getFormAvatar)
  yield takeLatest(scenarioActions.callHelperGotoFunc, callHelperGotoFunc)
  yield takeLatest(scenarioActions.callHelperCloseFunc, callHelperCloseFunc)
}