import {createAction} from 'redux-act'

export const handleTitle = createAction('HANDLE_TITLE')
export const handleLoader = createAction('HANDLE_LOADER')
export const handleClient = createAction('HANDLE_CLIENT')
export const handleCurrency = createAction('HANDLE_CURRENCY')

export const getClients = createAction('GET_CLIENTS')
export const handleClients = createAction('HANDLE_CLIENTS')
export const handleClearClient = createAction('HANDLE_CLEAR_CLIENT')
export const handleCurrentClient = createAction('HANDLE_CURRENT_CLIENT')
export const getCurrencies = createAction('GET_CURRENCIES')
export const handleCurrencies = createAction('HANDLE_CURRENCIES')
export const getUserAccounts = createAction('GET_USER_ACCOUNTS')
export const handleUserAccounts = createAction('HANDLE_USER_ACCOUNTS')

export const getCourses = createAction('GET_COURSES')
export const handleCourses = createAction('HANDLE_COURSES')

export const handleSidebarToggle = createAction('HANDLE_SIDEBAR_TOGGLE')
export const handleSidebarOpen = createAction('HANDLE_SIDEBAR_OPEN')

export const getSpendTypes = createAction('GET_SPEND_TYPES')
export const handleSpendTypes = createAction('HANDLE_SPEND_TYPES')

export const postCustomType = createAction('POST_CUSTOM_TYPE')
export const putCustomType = createAction('PUT_CUSTOM_TYPE')
export const postCustomGroupType = createAction('POST_CUSTOM_GROUP_TYPE')
export const postCustomClassType = createAction('POST_CUSTOM_CLASS_TYPE')

export const postCustomInsuranceCompany = createAction('POST_CUSTOM_INSURANCE_COMPANY')
export const getInsuranceCompanies = createAction('GET_INSURANCE_COMPANIES')
export const handleInsuranceCompanies = createAction('HANDLE_INSURANCE_COMPANIES')

export const getIncomeTypes = createAction('GET_INCOME_TYPES')
export const handleIncomeTypes = createAction('HANDLE_INCOME_TYPES')

export const getPropertyTypes = createAction('GET_PROPERTY_TYPES')
export const handlePropertyTypes = createAction('HANDLE_PROPERTY_TYPES')
export const getInvestTypes = createAction('GET_INVEST_TYPES')
export const handleInvestTypes = createAction('HANDLE_INVEST_TYPES')
export const getGroupTypes = createAction('GET_GROUP_TYPES')
export const handleGroupTypes = createAction('HANDLE_GROUP_TYPES')
export const getClassTypes = createAction('GET_CLASS_TYPES')
export const handleClassTypes = createAction('HANDLE_CLASS_TYPES')

export const getActiveTags = createAction('GET_ACTIVE_TAGS')
export const handleActiveTags = createAction('HANDLE_ACTIVE_TAGS')
export const getActiveSubcategories = createAction('GET_ACTIVE_SUBCATEGORIES')
export const handleActiveSubcategories = createAction('HANDLE_ACTIVE_SUBCATEGORIES')

export const getUserNotifications = createAction('GET_USER_NOTIFICATIONS')
export const handleUserNotifications = createAction('HANDLE_USER_NOTIFICATIONS')