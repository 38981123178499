import {all, fork} from 'redux-saga/effects'
import auth from './auth'
import interfaceComponents from './interface'
import scenario from './scenario'
import plan from './plan'
import jobs from './jobs'

export default function* rootSaga() {
  return yield all([
    auth,
    interfaceComponents,
    scenario,
    plan,
    jobs,
  ].map(fork))
}
