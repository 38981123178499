import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {
  HeaderContainer,
  HeaderLogo,
  HeaderMenu,
  HeaderOther,
  HeaderOtherComment,
  HeaderOtherLink,
  HeaderPersonal,
  HeaderWrapper,
  ModalText,
  Wrap,
} from './styles';
import {getUserAccounts, handleClient, handleCurrency} from "../../../actions/interface";
import {startScenario} from "../../../actions/scenario";
import {
  handleCloseModal,
  handleForgotModal,
  handleLoginModal,
  handleRegisterModal,
  handleSubscribeModal,
  logout
} from "../../../actions/auth";
import Login from "../../../pages/Auth/Login";
import Register from "../../../pages/Auth/Register";
import Forgot from "../../../pages/Auth/Forgot";
import {Modal, ModalClose, ModalContent, ModalFooter, ModalHeader} from "../../modal/Modal";
import {Button} from "finform";
import {Row} from "#app/pages/styles";
import withRouter from "../../../components/WithRouter/withRouter";
import {Title2} from "../../../components/Text/styles";
import RoboadvisorSubscribe from "#app/pages/Auth/RoboadvisorSubscribe";

const Header = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showFooter, setShowFooter] = useState(true);

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else if (window.pageYOffset === 0) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderLoginModal = () => (
    <Modal
      size='extra-small'
      onClose={() => {
        props.handleCloseModal();
      }}
      show={props.showLoginModal}
      trigger={''}
    >
      <ModalHeader>
        <Title2>{props.i18n.auth.login}</Title2>
        <ModalClose onClick={() => {
          props.handleCloseModal();
        }}><div /></ModalClose>
      </ModalHeader>
      <ModalContent>
        <Login header={this} />
      </ModalContent>
      <ModalFooter>
        <Row>
          <ModalText>{props.i18n.auth.haveProfile}</ModalText>
        </Row>
        <Row>
          <Button flex className='style1' id={'register-modal-button'} onClick={(e) => {
            e.preventDefault();
            props.handleRegisterModal();
          }}>{props.i18n.auth.register}</Button>
        </Row>
      </ModalFooter>
    </Modal>
  );

  const renderRegisterModal = () => (
    <Modal
      size='extra-small'
      onClose={() => {
        props.handleCloseModal();
      }}
      show={props.showRegisterModal}
      trigger={''}
    >
      <ModalHeader>
        <Title2>{props.i18n.auth.register}</Title2>
        <ModalClose onClick={() => {
          props.handleCloseModal();
        }}><div /></ModalClose>
      </ModalHeader>
      <ModalContent>
        <Register />
      </ModalContent>
      {showFooter && <ModalFooter>
        <Row>
          <ModalText>{props.i18n.auth.haveProfile}</ModalText>
        </Row>
        <Button flex className='style2' id='login-modal-button' onClick={(e) => {
          e.preventDefault();
          props.handleLoginModal();
        }}>{props.i18n.auth.enter}</Button>
      </ModalFooter>}
    </Modal>
  );

  const renderSubscribeModal = () => (
    <Modal
      size='extra-small'
      onClose={() => {
        props.handleCloseModal();
      }}
      show={props.showSubscribeModal}
      trigger={''}
    >
      <ModalHeader>
        <Title2 center style={{ width: '100%', lineHeight: '28px' }}>
          Уведомить о запуске <br /> Robo-advisor
        </Title2>
      </ModalHeader>
      <ModalContent>
        <RoboadvisorSubscribe callback={() => {
          props.handleSubscribeModal();
        }} />
      </ModalContent>
      {showFooter && <ModalFooter>
        <Button flex style={{ margin: '5px' }} className='style1' onClick={() => {
          props.handleCloseModal();
        }}>{props.i18n.auth.cancel}</Button>
      </ModalFooter>}
    </Modal>
  );

  const renderForgotModal = () => (
    <Modal
      size='extra-small'
      onClose={() => {
        props.handleCloseModal();
      }}
      show={props.showForgotModal}
      trigger={''}
    >
      <ModalHeader>
        <Title2>{props.i18n.auth.forgotPassword}</Title2>
        <ModalClose onClick={() => {
          props.handleCloseModal();
        }}><div /></ModalClose>
      </ModalHeader>
      <ModalContent>
        <Forgot callback={() => {
          props.handleLoginModal();
        }} />
      </ModalContent>
      {showFooter && <ModalFooter>
        <Button flex className='style1' onClick={() => {
          props.handleCloseModal();
        }}>{props.i18n.auth.cancel}</Button>
      </ModalFooter>}
    </Modal>
  );

  return (
    <HeaderWrapper>
      <HeaderContainer className={isScrolled ? 'scrolled' : ''}>
        <Wrap>
          <HeaderLogo><Link to="/">whiteswan</Link></HeaderLogo>
          <HeaderMenu style={isScrolled ? { transform: 'translate3d(0,-50%,0)', opacity: 1 } : {}}>
            <div>
              {/*<Link to="/#functions">Что умеет</Link>*/}
              {/*<Link to="/#prices">Сколько стоит</Link>*/}
              {/*<Link to="/#buy">Купить</Link>*/}
            </div>
          </HeaderMenu>
        </Wrap>
        <HeaderPersonal>
          <a id={'login-button'} className='style2' onClick={(e) => {
            e.preventDefault();

            if (props.auth) {
              props.navigate('/cabinet')
            } else {
              props.handleLoginModal();
            }
          }}>{props.i18n.auth.enter}</a>
        </HeaderPersonal>
        <HeaderOther>
          <a href="https://blackswan.finance">
            <HeaderOtherLink />
            <HeaderOtherComment style={isScrolled ? { display: 'none' } : {}}>{props.i18n.auth.crm}</HeaderOtherComment>
          </a>
        </HeaderOther>
      </HeaderContainer>
      {renderLoginModal()}
      {renderRegisterModal()}
      {renderForgotModal()}
      {renderSubscribeModal()}
    </HeaderWrapper>
  );
};

const enhance = connect(
  (state) => ({
    auth: state.auth.auth,
    data: state.auth.data,
    client: state.interfaceComponents.client,
    clients: state.interfaceComponents.clients,
    currency: state.interfaceComponents.currency,
    currencies: state.interfaceComponents.currencies,
    showLoginModal: state.auth.showLoginModal,
    showRegisterModal: state.auth.showRegisterModal,
    showForgotModal: state.auth.showForgotModal,
    showSubscribeModal: state.auth.showSubscribeModal,
    i18n: state.i18n,
  }),
  {
    handleClient,
    handleCurrency,
    getUserAccounts,
    logout,
    startScenario,
    handleLoginModal,
    handleCloseModal,
    handleRegisterModal,
    handleForgotModal,
    handleSubscribeModal
  }
)

export default withRouter(enhance(Header));