import React, {lazy, Suspense, useEffect, useLayoutEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {getUser, handleAuth, socketsConnecting} from '../actions/auth';
import {getPlan} from '../actions/plan';
import {getCourses, getCurrencies, getIncomeTypes, getSpendTypes, getUserAccounts} from '../actions/interface';
import {NotifyContainer} from "notify-component";
import NotFound from "../interface/error/NotFound";
import ErrorBoundary from "../interface/error/ErrorBoundary";
import {Tracker, Url} from "finhelper";
import {getFormAvatar, getFormData} from "../actions/scenario";

import HeaderLanding from '../interface/landing/header/Header'
import Layout from './Layout/Layout'
import InvestsProvider from "#app/contexts/InvestsProvider";

// Ленивая загрузка страниц
const MainPage = lazy(() => import('./../pages/MainPage/MainPage'));
const Balance = lazy(() => import('../pages/Accounting/Balance/Balance'));
const OldBalance = lazy(() => import('../pages/Accounting/Balance/OldBalance'));
const Dynamic = lazy(() => import('../pages/Accounting/Dynamic/Dynamic'));
const Invests = lazy(() => import('src/pages/Investing/Invests/Invests'));
const Case = lazy(() => import('../pages/Investing/Case/Case'));
const ArchiveInvests = lazy(() => import('src/pages/Investing/Invests/ArchiveInvests'));
const Incomes = lazy(() => import('../pages/IncomeAndExpenses/Incomes/Incomes'));
const Goals = lazy(() => import('../pages/Planing/Goals/Goals'));
const Plan = lazy(() => import('../pages/Planing/Plan/Plan'));
const SalaryPlan = lazy(() => import('../pages/Planing/SalaryPlan/SalaryPlan'));
const Spendings = lazy(() => import('../pages/IncomeAndExpenses/Spendings/Spendings'));
const Tactics = lazy(() => import('../pages/IncomeAndExpenses/Tactics/Tactics'));
const Logs = lazy(() => import('../pages/Accounting/Logs/Logs'));
const Clients = lazy(() => import('../pages/Clients/Clients'));
const Insurances = lazy(() => import('../pages/Accounting/Insurances/Insurances'));
const RoundDiagramsAndGroups = lazy(() => import('../pages/Investing/RoundDiagramsAndGroups'));
const TradingViewChart = lazy(() => import('../pages/Investing/TradingViewChart/TradingViewChart'));
const RoundDiagramView = lazy(() => import('../pages/Investing/RoundDiagrams/RoundDiagramView'));
const CommonProperty = lazy(() => import('../pages/Accounting/Common'));
const CommonConfig = lazy(() => import('../pages/Config/Common'));
const Notifications = lazy(() => import('../pages/NotificationPage/Notifications'));
const Page1 = lazy(() => import("../pages/RegisterScenario/Page1/Page1"));
const Page2 = lazy(() => import("../pages/RegisterScenario/Page2/Page2"));
const Page3 = lazy(() => import("../pages/RegisterScenario/Page3/Page3"));
const Forbidden = lazy(() => import("../interface/error/Forbidden/index"));
const UnexpectedError = lazy(() => import("../interface/error/UnexpectedError/index"));
const Ui = lazy(() => import("../interface/Ui/Ui"));
const Quizes = lazy(() => import("./../pages/QuizPage/Quizes"));

// Ленивая загрузка компонентов
const HeaderCabinet = lazy(() => import('../interface/cabinet/header/Header'));
const DemoSidebar = lazy(() => import('../interface/cabinet/sidebar/DemoSidebar'));
const SidebarCabinet = lazy(() => import('../interface/cabinet/sidebar/Sidebar'));
const AuthLayout = lazy(() => import('./AuthLayout/AuthLayout'));
const Scenario = lazy(() => import('../interface/scenario/Scenario'));
const Browser = lazy(() => import('../interface/browser/Browser'));
const Notification = lazy(() => import('../interface/notification/Notification'));
const CallbackNotification = lazy(() => import('../interface/notification/CallbackNotification'));

const pagesRouter = ({
                       auth,
                       client,
                       getUser,
                       clientLoading,
                       currencyLoading,
                       accountLoading,
                       formLoading,
                       incomeTypesLoading,
                       spendTypesLoading,
                       tagsLoading,
                       subcategoriesLoading,
                       demo
                     }) => {
  const location = useLocation();
  const [isActive, setActive] = useState();
  const navigate = useNavigate();

  const isLoaded = !clientLoading &&
    !currencyLoading &&
    !accountLoading &&
    !formLoading &&
    !incomeTypesLoading &&
    !spendTypesLoading &&
    !tagsLoading &&
    !subcategoriesLoading;

  useLayoutEffect(() => {
    Tracker.init();
    getUser({
      callback: () => {
        setActive(true);
      },
      fallback: () => {
        setActive(true);
      }
    });
  }, []);

  useEffect(() => {
    Tracker.hit(location.pathname);
  }, [location]);

  useEffect(() => {
    if (auth && !Url.getCurrentUrl().includes('cabinet'))
      navigate('cabinet/accounting/dashboard');
  }, [auth]);

  let scenarioName = client?.scenario?.name;

  if (typeof isActive === 'undefined') {
    return (null);
  }

  return (
    <ErrorBoundary>
      <Routes>
        {auth ?
          <Route path="/cabinet" element={<>
            <Suspense>
              <HeaderCabinet />
              {isLoaded && <InvestsProvider>
                {!!client && demo && scenarioName === 'demo' ? <DemoSidebar /> : <SidebarCabinet />}
              </InvestsProvider>}
              <AuthLayout />
              <Scenario />
              <Browser />
              <Notification />
              <CallbackNotification />
            </Suspense>
          </>}>
            <Route path={"/cabinet/register/page1"} element={<Suspense><Page1 /></Suspense>} />
            <Route path={"/cabinet/register/page2"} element={<Suspense><Page2 /></Suspense>} />
            <Route path={"/cabinet/register/page3"} element={<Suspense><Page3 /></Suspense>} />

            <Route path={"/cabinet/accounting/dashboard"} element={<Suspense><Balance /></Suspense>} />
            <Route path={"/cabinet/accounting/balance"} element={<Suspense>
              <InvestsProvider>
                <OldBalance />
              </InvestsProvider>
            </Suspense>} />
            <Route path={"/cabinet/accounting/dynamic"} element={<Suspense><Dynamic /></Suspense>} />
            <Route path={"/cabinet/accounting/accounts-properties-obligations"} element={<Suspense><CommonProperty /></Suspense>} />
            <Route path={"/cabinet/accounting/logs"} element={<Suspense><Logs /></Suspense>} />
            <Route path={"/cabinet/accounting/clients"} element={<Suspense><Clients /></Suspense>} />

            <Route path={"/cabinet/plan/plan"} element={<Suspense><Plan /></Suspense>} />
            <Route path={"/cabinet/plan/salary-plan"} element={<Suspense><SalaryPlan /></Suspense>} />
            <Route path={"/cabinet/plan/goals"} element={<Suspense><Goals /></Suspense>} />
            <Route path={"/cabinet/plan/insurances"} element={<Suspense><Insurances /></Suspense>} />

            <Route path={"/cabinet/income-and-expenses"} element={<Suspense><Tactics /></Suspense>} />
            <Route path={"/cabinet/income-and-expenses/tactics"} element={<Suspense><Tactics /></Suspense>} />
            <Route path={"/cabinet/income-and-expenses/income"} element={<Suspense><Incomes /></Suspense>} />
            <Route path={"/cabinet/income-and-expenses/spendings"} element={<Suspense><Spendings /></Suspense>} />

            <Route path={"/cabinet/investing/case"} element={<Suspense><Case /></Suspense>} />
            <Route path={"/cabinet/investing/invest"} element={<Suspense><Invests /></Suspense>} />
            <Route path={"/cabinet/investing/archive-invest"} element={<Suspense><ArchiveInvests /></Suspense>} />
            <Route path={"/cabinet/investing/diagrams/view/:id"} element={<Suspense><RoundDiagramView /></Suspense>} />
            <Route path={"/cabinet/investing/diagrams"} element={<Suspense><RoundDiagramsAndGroups /></Suspense>} />
            <Route path={"/cabinet/investing/chart"} element={<Suspense><TradingViewChart /></Suspense>} />

            <Route path={"/cabinet/config/spending-category-income-category-spending-budget-integrations-security"} element={<Suspense><CommonConfig /></Suspense>} />

            <Route path={"/cabinet/quizes"} element={<Suspense><Quizes /></Suspense>} />
            <Route path={"/cabinet/notifications"} element={<Suspense><Notifications /></Suspense>} />

            <Route path={"/cabinet/error"} element={<Suspense><UnexpectedError /></Suspense>} />
            <Route path={"/cabinet/ui"} element={<Suspense><Ui /></Suspense>} />
            <Route path={"/cabinet"} element={<Suspense>
              <InvestsProvider>
                <Balance />
              </InvestsProvider>
            </Suspense>} />

            <Route element={<NotFound />} />
          </Route>
          :
          <Route path="/cabinet" element={<Navigate to='/login' />} />
        }
        <Route element={<>
          <HeaderLanding />
          <Layout />
        </>}>
          <Route path="/roboadvisor" element={<Suspense><MainPage /></Suspense>} />
          <Route path="/agreement" element={<Suspense><MainPage /></Suspense>} />
          <Route path="/policy" element={<Suspense><MainPage /></Suspense>} />
          <Route path="/" element={<Suspense><MainPage /></Suspense>} />
          <Route path="/login" element={<Suspense><Forbidden /></Suspense>} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
      <NotifyContainer />
    </ErrorBoundary>
  );
};

const enhance = connect(
  state => ({
    auth: state.auth.auth,
    data: state.auth.data,
    language: state.auth.language,
    scenario: state.scenario.data,
    client: state.interfaceComponents.client,
    clientLoading: state.interfaceComponents.clientLoading,
    currencyLoading: state.interfaceComponents.currencyLoading,
    accounts: state.interfaceComponents.accounts,
    accountLoading: state.interfaceComponents.accountLoading,
    sidebarOpen: state.interfaceComponents.sidebarOpen,
    incomeTypesLoading: state.interfaceComponents.incomeTypesLoading,
    spendTypesLoading: state.interfaceComponents.spendTypesLoading,
    tagsLoading: state.interfaceComponents.tagsLoading,
    subcategoriesLoading: state.interfaceComponents.subcategoriesLoading,
    formLoading: state.scenario.formLoading,
    tutorial: state.scenario.tutorial,
    demo: state.scenario.demo,
  }),
  { getUser, handleAuth, getUserAccounts, getCurrencies, getFormData, getFormAvatar, getCourses, getSpendTypes, getIncomeTypes, socketsConnecting, getPlan }
);

export default enhance(pagesRouter);