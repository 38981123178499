import React from 'react'
import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import * as authActions from '../actions/auth'
import * as interfaceActions from '../actions/interface'
import * as scenarioActions from '../actions/scenario'
import * as planActions from '../actions/plan';
import {Api} from "laravel-request";
import {ClientConstants, Tracker, Url} from "finhelper"
import {handleNotify} from "#app/sagas/jobs";

function* handleScenario(response)
{
  if (response.data)
  {
    let json = response.data.scenario;

    if (json && json.name)
    {
      switch (json.name)
      {
        case 'register':
          let page = parseInt(json.page);
          if(page)
          {
            // yield call(history.push, Scenario1.routes[page - 1]);break;
          }
          break;
      }
    }else{
      let url = Url.getCurrentUrl();

      if(url.indexOf('/cabinet') === -1)
      {
        // yield call(history.push, '/cabinet');
      }
    }
  }
}

function* login(action) {
  try {
    const {form, success, error, obj} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('auth', 'login', form)
        .withValidateForm(obj)
        .withoutNotify((status) => {
          return status !== 200;
        })
        .call((response, status, xhr) => {
          success(response, status, xhr);
          resolve(response);
        }, (errors) => {
          console.error(errors);
          error(errors);
        });
    });

    let response = yield promise;

    yield put(authActions.handleGetUser(response));

    yield handleScenario(response);

    yield put(authActions.handleAuth())

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}
function* register(action) {
  try {
    const {form, success, error, obj, callback} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('auth', 'register', form)
        .withValidateForm(obj)
        .withoutNotify((status) => {
          return status !== 200;
        })
        .call((response, status, xhr) => {
          Tracker.reachGoal('register_finished', {
            //user_id: this.props.client ? this.props.client.id : null
          });
          success(response, status, xhr);
          resolve(response);
        }, (errors) => {
          console.error(errors);
          error(errors);
        });
    });

    let response = yield promise;
    yield put(authActions.handleGetUser(response));

    yield handleScenario(response);

    yield put(authActions.handleAuth())

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }

  } catch (err) {
    console.error(err);
  }
}

function* requestRegister(action) {
  try {
    const {form, success, error, obj} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('auth', 'request-register', form)
        .withValidateForm(obj)
        .withoutNotify((status) => {
          return status !== 200;
        })        .call((response, status, xhr) => {
          Tracker.reachGoal('request_register_code', {
            // //user_id: this.props.client ? this.props.client.id : null
          });
          success(response, status, xhr);
          resolve(response);
        }, (errors) => {
          console.error(errors);
          error(errors);
        });
    });
  } catch (err) {
    console.error(err);
  }
}
function* forgot(action) {
  try {
    const {form, success, error, obj} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('auth', 'forgot', form)
        .withValidateForm(obj)
        .withoutNotify((status) => {
          return status !== 200;
        })
        .call((response, status, xhr) => {
          success(response, status, xhr);
          resolve(response);
        }, (errors) => {
          console.error(errors);
          error(errors);
        });
    });

    let response = yield promise;

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }

  } catch (err) {
    console.error(err);
  }
}

function* requestForgot(action) {
  try {
    const {form, success, error, obj} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('auth', 'request-forgot', form)
        .withValidateForm(obj)
        .withoutNotify((status) => {
          return status !== 200;
        })
        .call((response, status, xhr) => {
          success(response, status, xhr);
          resolve(response);
        }, (errors) => {
          console.error(errors);
          error(errors);
        });
    });
  } catch (err) {
    console.error(err);
  }
}

function* logout(action) {
  try {
    const {success, error, obj, navigate} = action.payload;

    yield put(authActions.handleLogout());
    yield put(planActions.handlePlanLogout());
    if(typeof navigate === 'function')
    {
      yield call(navigate, '/');
    }

    const promise = new Promise((resolve, reject) => {
      Api.get('auth', 'logout').call((response) => {
        if(response.result === 'success')
        {
          success();
          resolve(response);
        }
      }, (errors) => {
        console.error(errors);
        error(errors);
      });
    });

    let response = yield promise;
    yield put(interfaceActions.handleClearClient());
    yield handleNotify({data: {jobs: []}});

    location.reload()

  } catch (err) {
    console.error(err);
  }
}

function* socketsConnecting(action)
{
 //call middleware
}

function* getUser(action) {
  try {
    let tmpToken = localStorage.getItem('token');
    let data = {};

    if(tmpToken)
    {
      data.token = tmpToken;
    }

    const promise = new Promise((resolve, reject) => {
      Api.makeRequest(
        {
          method: "GET",
          url: process.env.REACT_APP_API_URL + '/api/v1/call/profile/index',
          data: data,
          success: (response, status, xhr) => {
            resolve(response);
          },
          error: (response) => {
            reject();
          }
        }
      )
    });

    //если по токену входит, то нужно удалить его из памяти
    localStorage.removeItem('token')

    let response = yield promise;

    if(response.data?.api_token)
    {
      localStorage.setItem('api_token', response.data?.api_token);
    }

    yield put(authActions.handleGetUser(response));

    yield handleScenario(response);

    if(typeof action.payload === 'undefined' || action.payload.load !== false)
    {
      yield put(authActions.handleAuth());
    }

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }

    //если авторизован, то чат без доп. полей в форме
    console.log('User is authorized')
    // ChatLoad.loadScript(true)

  } catch (err) {
    console.log('User is not auth')

    // ChatLoad.loadScript()

    let url = Url.getCurrentUrl();

    if(url.indexOf('/cabinet') !== -1)
    {
      // this.props.navigate('/login');
    }

    if(action.payload && typeof action.payload.fallback === "function")
    {
      yield call(action.payload.fallback);
    }

    console.warn(err);
  }
}



function* postUser(action) {
  try {
    const {form, success, error, obj} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.post('profile', 'profile', form).withValidateForm(obj).call((response, status, xhr) => {
        success(response, status, xhr);
        resolve(response);
      }, (errors) => {
        console.error(errors);
        error(errors);
      });
    });

    let response = yield promise;

    yield put(authActions.handleGetUser(response));

    yield handleScenario(response);

    yield put(authActions.handleAuth());
  } catch (err) {
    console.error(err);
  }
}

function* handleAuth(action) {
  try {
    const state = yield select();

    if(state.auth && state.auth.data)
    {
      let lang = localStorage.getItem('language_id');

      //если язщык не совпадает с тем что вернулся с бека, то нужно перезагрузить страницу
      if(state.auth.data.language_id && (typeof lang === 'undefined' || lang === null || parseInt(state.auth.data.language_id) !== parseInt(lang)))
      {
        localStorage.setItem('language_id', state.auth.data.language_id);
        location.reload()
      }

      switch (state.auth.data.role)
      {
        case ClientConstants.CLIENT:
          yield put(interfaceActions.handleCurrentClient(state.auth.data));
          yield put(scenarioActions.handleStartTutorial(state.auth.data))

          yield put(scenarioActions.getFormData());
          yield put(interfaceActions.getUserAccounts());
          yield put(interfaceActions.getUserNotifications());
          yield put(interfaceActions.getActiveTags());
          yield put(interfaceActions.getActiveSubcategories());
          yield put(interfaceActions.getCourses());
          yield put(interfaceActions.getCurrencies({callback: function* () {
            yield put(planActions.getPlan());
          }}));

          const newState = yield select();

          if(newState.interfaceComponents.client)
          {
            yield put(authActions.socketsConnecting({
              userId: newState.interfaceComponents.client.id,
              role: 'client'
            }));
          }

          if(action.payload && typeof action.payload.callback === "function")
          {
            yield call(action.payload.callback);
          }
          break;
        case ClientConstants.MANAGER:
        case ClientConstants.OWNER:
        case ClientConstants.DEPARTMENT_BOSS:
        case ClientConstants.DIRECTOR:
          yield put(interfaceActions.getClients({callback: function* () {
              yield put(scenarioActions.getFormData());
              yield put(interfaceActions.getUserAccounts());
              yield put(interfaceActions.getUserNotifications());
              yield put(interfaceActions.getSpendTypes());
              yield put(interfaceActions.getIncomeTypes());
              yield put(interfaceActions.getActiveTags());
              yield put(interfaceActions.getActiveSubcategories());
              yield put(interfaceActions.getPropertyTypes());
              yield put(interfaceActions.getInvestTypes());
              yield put(interfaceActions.getGroupTypes());
              yield put(interfaceActions.getClassTypes());
              yield put(interfaceActions.getCourses());
              yield put(interfaceActions.getCurrencies({callback: function* () {
                yield put(planActions.getPlan());
              }}));

              const newState = yield select();

              if(newState.interfaceComponents.client)
              {
                yield put(authActions.socketsConnecting({
                  userId: newState.interfaceComponents.client.id,
                  role: 'manager'
                }));
              }
          }}));
          break;
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export {
  handleScenario
}

export default function* watcher() {
  yield takeEvery('*', function* logger(action) {
    const state = yield select()

    // console.log('action', action)
    // console.log('state after', state)
  })
  yield takeLatest(authActions.login, login)
  yield takeLatest(authActions.register, register)
  yield takeLatest(authActions.requestRegister, requestRegister)
  yield takeLatest(authActions.forgot, forgot)
  yield takeLatest(authActions.requestForgot, requestForgot)
  yield takeLatest(authActions.logout, logout)
  yield takeLatest(authActions.getUser, getUser)
  yield takeLatest(authActions.postUser, postUser)
  yield takeLatest(authActions.handleAuth, handleAuth)
  yield takeLatest(authActions.socketsConnecting, socketsConnecting)
}
