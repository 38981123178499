import {createReducer} from 'redux-act'
// import ru from "../translations/ru";
// import en from "../translations/en";
import {Url} from "finhelper"

let data = {

};

let lang = localStorage.getItem('language_id');

let url = Url.getCurrentUrl();

if(url.includes('/ru/'))
{
  data = require('../translations/ru').default;
}else if(url.includes('/en/'))
{
  data = require('../translations/en').default;
}else if(parseInt(lang) === 1 || lang === null)
{
  data = require('../translations/ru').default;
}else{
  data = require('../translations/en').default;
}



const reducer = createReducer({
}, data);

export default reducer
