import {createReducer} from 'redux-act'
import * as actions from '../actions/auth'
import {IndexedDBCache, Tracker, Url} from "finhelper"


let lang = localStorage.getItem('language_id');
let clientId = localStorage.getItem('client_id');
let url = Url.getCurrentUrl();
let params = Url.getParams();

let language = 1;
if(url.includes('/ru/'))
{
  localStorage.setItem('language_id', 1)
  language = 1;
}else if(url.includes('/en/'))
{
  localStorage.setItem('language_id', 2)
  language = 2;
}else if(parseInt(lang) === 1 || lang === null)
{
  language = 1;
}else{
  language = 2;
}

if(typeof params.crm_client_id !== 'undefined')
{
  localStorage.setItem('crm_client_id', params.crm_client_id)
}

if(typeof params.token !== 'undefined')
{
  localStorage.setItem('token', params.token)
}

let user = {
  data: {},
  language: language,
  auth: false,
  showLoginModal: false,
  showRegisterModal: false,
  showForgotModal: false,
  showSubscribeModal: false,
};


if(url === '/roboadvisor')
{
  
  user.showSubscribeModal = true;
}

//если урл страницы авторазиции, то покажем модальное окно
if(url === '/login' || url === '/en/login' || url === '/ru/login')
{
  
  user.showLoginModal = true;
}


const handleLanguage = (state, language) => {
  return {
    ...state,
    language: language
  }
}

const handleGetUser = (state, response) => {

  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      data: response.data,
      auth: true
    }
  }else{
    return state;
  }
}

const handleLogout = (state) => {
  localStorage.clear();
  IndexedDBCache.clearAll();

  return {
    ...state,
    data: {},
    auth: false
  }
}

const handleLoginModal = (state) => {
  return {
    ...state,
    showLoginModal: true,
    showRegisterModal: false,
    showForgotModal: false,
  }
}

const handleForgotModal = (state) => {
  return {
    ...state,
    showForgotModal: true,
    showLoginModal: false,
    showRegisterModal: false,
  }
}

const handleRegisterModal = (state) => {
  Tracker.reachGoal('register_click', {
    // //user_id: this.props.client ? this.props.client.id : null
  });

  return {
    ...state,
    showRegisterModal: true,
    showForgotModal: false,
    showLoginModal: false,
  }
}

const handleCloseModal = (state) => {
  return {
    ...state,
    showLoginModal: false,
    showRegisterModal: false,
    showForgotModal: false,
    showSubscribeModal: false,
  }
}

const reducer = createReducer({
  [actions.handleGetUser]: handleGetUser,
  [actions.handleLogout]: handleLogout,
  [actions.handleLoginModal]: handleLoginModal,
  [actions.handleForgotModal]: handleForgotModal,
  [actions.handleRegisterModal]: handleRegisterModal,
  [actions.handleCloseModal]: handleCloseModal,
  [actions.handleLanguage]: handleLanguage,
}, user)

export default reducer
