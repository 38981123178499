import {createReducer} from 'redux-act'
import * as actions from '../actions/interface'
import {ActiveTagConstants, ClientConstants, ClientModel, CurrencyConstants, ModelHelper} from "finhelper"


let state = {
  title: '',
  loader: false,
  clients: [],
  client: null,
  clientLoading: true,
  currency: null,
  currencyLoading: true,
  currencies: [],
  accounts: [],
  accountLoading: true,
  courses: [],
  coursesLoading: true,
  sidebarOpen: localStorage.getItem('sidebar') ? JSON.parse(localStorage.getItem('sidebar')) : true,

  groupTypes: [],
  groupTypesLoading: true,
  classTypes: [],
  classTypesLoading: true,
  investTypes: [],
  investTypesLoading: true,
  propertyTypes: [],
  propertyTypesLoading: true,
  insuranceTypes: [],
  insuranceTypesLoading: true,
  insuranceCompanies: [],
  insuranceCompaniesLoading: true,
  incomeTypes: [],
  incomeTypesLoading: true,
  spendTypes: [],
  spendTypesLoading: true,
  incomeTags: [],
  spendTags: [],
  investTags: [],
  tags: [],
  tagsLoading: true,
  incomeSubcategories: [],
  spendSubcategories: [],
  subcategoriesLoading: true,

  notifications: [],
  notificationsLoading: true
};

const handleTitle = (state, title) => {
  document.title = title;
  return {
    ...state,
    title: title
  };
}

const handleLoader = (state, bool) => {
  return {
    ...state,
    loader: bool
  };
}

const handleUserNotifications = (state, response) => {

  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      notifications: response.data,
      notificationsLoading: false
    }
  }else {
    return state;
  }
}

const handleClient = (state, client) => {
  localStorage.setItem('client', JSON.stringify(client))

  return {
    ...state,
    client: client,
    clientLoading: false
  };
}

const handleCurrency = (state, currency) => {
  localStorage.setItem('currency', JSON.stringify(currency))

  return {
    ...state,
    currency: currency,
    currencyLoading: false
  };
}

const handleClients = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      clients: ClientModel.load(response.data)
    }
  }else{
    return state;
  }
}

const handleCurrentClient = (state, client) => {
  if(client)
  {
    switch (client.role)
    {
      case ClientConstants.CLIENT:

        let data = {
          ...state,
          client: client,
          clientLoading: false
        };

        if(typeof client.get_property_types !== 'undefined')
        {
          data.propertyTypes = client.get_property_types;
          data.propertyTypesLoading = false;
        }
        if(typeof client.get_insurance_types !== 'undefined')
        {
          data.insuranceTypes = client.get_insurance_types;
          data.insuranceTypesLoading = false;
        }
        if(typeof client.get_group_types !== 'undefined')
        {
          data.groupTypes = client.get_group_types;
          data.groupTypesLoading = false;
        }
        if(typeof client.get_class_types !== 'undefined')
        {
          data.classTypes = client.get_class_types;
          data.classTypesLoading = false;
        }
        if(typeof client.get_class_types !== 'undefined')
        {
          data.classTypes = client.get_class_types;
          data.classTypesLoading = false;
        }
        if(typeof client.get_income_types !== 'undefined')
        {
          data.incomeTypes = client.get_income_types;
          data.incomeTypesLoading = false;
        }
        if(typeof client.get_invest_types !== 'undefined')
        {
          data.investTypes = client.get_invest_types;
          data.investTypesLoading = false;
        }
        if(typeof client.get_spending_types !== 'undefined')
        {
          data.spendTypes = client.get_spending_types;
          data.spendTypesLoading = false;
        }

        return data;
      case ClientConstants.MANAGER:

        break;
    }
  }

  return {
    ...state
  }
}
const handleClearClient = (state, client) => {
  localStorage.removeItem('client');

  return {
    ...state,
    client: null,
    clientLoading: true
  };
}

const handleCurrencies = (state, data) => {
  let currency = localStorage.getItem('currency') ? JSON.parse(localStorage.getItem('currency')) : (data[0] ? data[0] : null);

  CurrencyConstants.currencies = data;

  return {
    ...state,
    currency: currency,
    currencyLoading: !currency,
    currencies: data
  }
}

const handleUserAccounts = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    ModelHelper.accounts = response.data;

    return {
      ...state,
      accounts: response.data,
      accountLoading: false
    }
  }else{
    return {
      ...state,
      accountLoading: false
    }
  }
}

const handleCourses = (state, data) => {
  CurrencyConstants.courses = data;

  return {
    ...state,
    courses: data,
    coursesLoading: false
  }
}

const handleSpendTypes = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      spendTypes: response.data,
      spendTypesLoading: false
    }
  }else{
    return state;
  }
}
const handleInsuranceCompanies = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      insuranceCompanies: response.data,
      insuranceCompaniesLoading: false
    }
  }else{
    return state;
  }
}

const handleIncomeTypes = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      incomeTypes: response.data,
      incomeTypesLoading: false
    }
  }else{
    return state;
  }
}

const handleSidebarToggle = (state) => {
  localStorage.setItem('sidebar', JSON.stringify(!state.sidebarOpen))

  return {
    ...state,
    sidebarOpen: !state.sidebarOpen
  }
}

const handleSidebarOpen = (state) => {
  localStorage.setItem('sidebar', JSON.stringify(true))

  return {
    ...state,
    sidebarOpen: true
  }
}

const handleActiveTags = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    let incomeTags = [],
        investTags = [],
        spendTags = [],
        tags = [];

    if(Array.isArray(response.data))
    {
      response.data.forEach((item) => {
        tags.push(item)

        if(item.type_id === ActiveTagConstants.INCOME){
          incomeTags.push(item);
        }else if(item.type_id === ActiveTagConstants.SPEND){
          spendTags.push(item);
        }else if(item.type_id === ActiveTagConstants.INVEST){
          investTags.push(item);
        }
      })
    }

    return {
      ...state,
      tags: tags,
      incomeTags: incomeTags,
      spendTags: spendTags,
      investTags: investTags,
      tagsLoading: false
    }
  }else{
    return state;
  }
}

const handleActiveSubcategories = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    let incomeSubcategories = [],
      spendSubcategories = [];

    if(Array.isArray(response.data))
    {
      response.data.forEach((item) => {
        if(item.type_id === ActiveTagConstants.INCOME){
          incomeSubcategories.push(item);
        }else if(item.type_id === ActiveTagConstants.SPEND){
          spendSubcategories.push(item);
        }
      })
    }

    return {
      ...state,
      incomeSubcategories: incomeSubcategories,
      spendSubcategories: spendSubcategories,
      subcategoriesLoading: false
    }
  }else{
    return state;
  }
}

const handlePropertyTypes = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      propertyTypes: response.data,
      propertyTypesLoading: false
    }
  }else{
    return state;
  }
}

const handleInvestTypes = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      investTypes: response.data,
      investTypesLoading: false
    }
  }else{
    return state;
  }
}

const handleGroupTypes = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      groupTypes: response.data,
      groupTypesLoading: false
    }
  }else{
    return state;
  }
}

const handleClassTypes = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    return {
      ...state,
      classTypes: response.data,
      classTypesLoading: false
    }
  }else{
    return state;
  }
}

const reducer = createReducer({
  [actions.handleTitle]: handleTitle,
  [actions.handleCurrency]: handleCurrency,
  [actions.handleClient]: handleClient,
  [actions.handleClients]: handleClients,
  [actions.handleCurrentClient]: handleCurrentClient,
  [actions.handleCurrencies]: handleCurrencies,
  [actions.handleUserAccounts]: handleUserAccounts,
  [actions.handleCourses]: handleCourses,
  [actions.handleClearClient]: handleClearClient,
  [actions.handleLoader]: handleLoader,
  [actions.handleSidebarToggle]: handleSidebarToggle,
  [actions.handleSidebarOpen]: handleSidebarOpen,
  [actions.handleSpendTypes]: handleSpendTypes,
  [actions.handleIncomeTypes]: handleIncomeTypes,
  [actions.handleActiveTags]: handleActiveTags,
  [actions.handleActiveSubcategories]: handleActiveSubcategories,
  [actions.handlePropertyTypes]: handlePropertyTypes,
  [actions.handleInvestTypes]: handleInvestTypes,
  [actions.handleGroupTypes]: handleGroupTypes,
  [actions.handleClassTypes]: handleClassTypes,
  [actions.handleUserNotifications]: handleUserNotifications,
  [actions.handleInsuranceCompanies]: handleInsuranceCompanies,
}, state)

export default reducer
